<template>
    <!-- <div class="service-wrap ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                
            </div>
        </div>
    </div> -->
    <div class="pricing-table-wrap pt-20 pb-20">
        <div class="container">
            <!-- <div class="row">
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="section-title style1 text-center mb-40">
                        <p>Télécharger les documents</p>
                    </div>
                </div>
            </div> -->
            <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-12 col-md-12" >
                    <div class="pricing-table style1">
                        <div class="pricing-header">
                            <h3><img src="../../assets/images/pricing-star.png" alt="Image">Règlémentatin UPU et Divers Guides</h3>
                        </div>
                         
                          
                        <!--<div class="col-xl-12 col-lg-12 col-md-12" v-for="(article, index) in articles" :key="index">
                            <div class="pricing-features" >
                                <ul class="list-style">
                                    <li v-if="article.source" class="checked"><i class="ri-check-line"></i><a class="link" :href="article.source" target="_blank" rel="noopener noreferrer">{{ article.titre }}</a>
                                        <hr>
                                    </li>
                                    <li v-else class="checked"><i class="ri-check-line"></i>
                                        <a class="link" :href="getUrlApiForFiles(article.urlImage)" target="_blank" rel="noopener noreferrer">{{ article.titre }}</a>
                                        <hr>
                                    </li>
                                </ul>
                            </div>
                        </div>-->

                    </div>
                    

                </div>

                <div>
                    <a
                    class="btn btn-primary" style="font-size: 19px;padding: 8px 10px;margin: 5px 5px;"
                    v-for="(article, index) in articles" :key="index"
                    target="_blank" rel="noopener noreferrer"
                    :href="article.source ? article.source : getUrlApiForFiles(article.urlImage)">
                        {{ article.titre }}
                        <i class="ri-download-fill"></i>
                    </a>
                </div>
                
                <!-- <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="pricing-table style1">
                        <div class="pricing-header">
                            <h3><img src="../../assets/images/pricing-star.png" alt="Image">Divers guides</h3>
                            
                        </div>
                        <div class="pricing-features">
                            <ul class="list-style">
                                <li class="checked"><i class="ri-check-line"></i><a class="link" href="" target="_blank" to="#">Conventions UPU</a></li>
                                <li class="checked"><i class="ri-check-line"></i><a class="link" href="" target="_blank" to="#">Receuil de la poste au lettre</a></li>
                                <li class="checked"><i class="ri-check-line"></i><a class="link" href="" target="_blank" to="#">Liste des distances aéropostales</a></li>
                                <li class="checked"><i class="ri-check-line"></i><a class="link" href="" target="_blank" to="#">Recueil des normes de distribution</a></li>
                                <li class="checked"><i class="ri-check-line"></i><a class="link" href="" target="_blank" to="#">Circulaires</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import ApiService from "@/services/ApiService";
import { getUrlApiForFiles, error,format_date } from "@/utils/utils";
import { defineComponent, ref, onMounted } from "vue";
import { Article } from "@/models/Article";

export default defineComponent({
    name: 'DocumentPoste',
    components: {
  },
  setup(){

    const articles = ref<Array<Article>>([]);   

    function getTreeArticle() {
      return ApiService.get(`/actualite/articles/65`)
        .then(({ data }) => {
          articles.value = data.data.data;
          console.log(articles.value);
          return data.data;
        })
        .catch(({ response }) => {
          error(response.message)
      });
    }
    
    onMounted(() => {
        getTreeArticle();
    });

    return { 
        articles,
        getUrlApiForFiles,
        format_date,
    };
  },
});
</script>

<style>

</style>