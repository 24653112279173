<template>
    <div class="shopping-wrap  bg-albastor pt-20 pb-20">
        <div class="container">
            <div class="row align-items-center" v-for="apropos in aproposs" :key="apropos.id">
                <div class="col-lg-6 col-12 order-lg-1 order-2">
                    <div class="shopping-content" >
                        <img src="../../assets/images/shopping/shopping-shape-1.png" alt="Image" class="shopping-shape-one moveHorizontal">
                        <img src="../../assets/images/shopping/shopping-shape-2.png" alt="Image" class="shopping-shape-two bounce">
                        <div class="content-title style1 " >
                            <span>Message du Directeur Général</span>
                            <div v-html="apropos.annoncedg"></div>
                        </div>
                        <!-- <ul class="content-feature-list list-style">
                            <li>Madame Judith B. GLIDJA.</li>
                            <li>Directrice Générale de LPB-SA.</li>
                        </ul> -->
                        <!-- <router-link to="/register" class="btn style1">Lire la suite</router-link> -->
                    </div>
                </div>
                <div class="col-lg-6 col-12 order-lg-2 order-1">
                    <div class="shopping-img-wrap">
                        <img :src="getUrlApiForFiles(apropos?.urlImage)" alt="Image" >
                        <!-- <img src="../../assets/images/shopping/shopping-1.png" alt="Image"> -->
                        <img src="../../assets/images/shopping/shopping-shape-3.png" alt="Image" class="shopping-shape-three">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref} from "vue";
import { Apropos } from "../../models/Apropos";
import { error, getUrlApiForFiles } from "../../utils/utils";
import axios from "axios";

export default defineComponent({
    name: 'OnlineShopping',

    components: {
  },
  setup(){
    const aproposs = ref<Array<Apropos>>([]);   
    const apropos = ref<Apropos>();
    
    function getAnnonceDg() {
        return ApiService.get('/apropos/statut/1')
        .then(response => {
            aproposs.value = response.data.data;
            console.log('Aprpos', aproposs.value);
            return response.data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }

    onMounted(() => {
        getAnnonceDg();
    });

    return { 
      aproposs,
      apropos,
      getUrlApiForFiles,
    };
  },
});
</script>