<template>
    <div class="about-wrap style1 pt-20 pb-20">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-4">
                    <div class="sidebar">
                        <div class="sidebar-widget style2">
                            <div class="category-box style2">
                                <ul class="list-style">
                                    <li>
                                        <a  @click="showVa" href="#valeur" class="nav-link">
                                            Valeur
                                            <span><i class="flaticon-next"></i></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#vison" class="nav-link"  @click="showV">
                                            Vision
                                            <span><i class="flaticon-next"></i></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a  @click="showM" href="#mission" class="nav-link">
                                            Mission
                                            <span><i class="flaticon-next"></i></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a  @click="showH" href="#historique" class="nav-link">
                                            Historique
                                            <span><i class="flaticon-next"></i></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a  @click="showO" href="#organigramme" class="nav-link">
                                            Organigramme
                                            <span><i class="flaticon-next"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div v-for="apropos in Aproposs" :key="apropos.id">
                        <div class="about-content" v-if="valeur">
                            <div class="content-title style1">
                                <h2>Les valeurs que nous promouvons sont:
                                </h2>
                                <p class="mb-20" v-html="apropos.valeur"></p>
                                <img src="../../assets/images/about/Poste-AuxLettres.png" />
                            </div>
                        </div>
                        <div  class="about-content" v-if="vision">
                            <div class="content-title style1" id="vision">
                                <h2>La vision de La Poste du Bénin SA est :</h2>
                                <p class="mb-20" v-html="apropos.vision"></p>
                                <img src="../../assets/images/about/Transport-de-Cois-et-Marchandises.png" />
                            </div>
                        </div>
                        <div class="about-content" v-if="mission">
                            <div class="content-title style1" id="mission">
                                <h2>Notre mission est:</h2>
                                <p class="mb-20" v-html="apropos.mission"></p>
                                <img src="../../assets/images/about/Colis-Postaux.png" />
                            </div>
                        </div>
                        <div class="about-content" v-if="historique" id="historique">
                            <div class="content-title style1">
                                <h2>Historique de La Poste du Bénin SA</h2>
                                <p v-html="apropos.historique"></p>
                            </div>
                        </div>

                        <div class="about-content" v-if="organigramme">
                            <div class="content-title style1" id="organigramme">
                                <h2>Notre organigramme :</h2>
                                <p v-html="apropos.organigramme"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ApiService from "@/services/ApiService";
import { defineComponent, onMounted, ref} from "vue";
import { Apropos } from "../../models/Apropos";
import { error } from "../../utils/utils";
import axios from "axios";

export default defineComponent({
    name: 'SmartBanking',
    components: {},
    setup() {

        const Aproposs = ref<Array<Apropos>>([]);   
        const vision = ref(false);
        const mission = ref(false);
        const historique = ref(false);
        const organigramme = ref(false);
        const valeur = ref(true);

        const showV = () => {
            vision.value = true;
            mission.value = false;
            historique.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showVa = () => {
            valeur.value = true;
            vision.value = false;
            mission.value = false;
            historique.value = false;
            organigramme.value = false;
        }

        const showM = () => {
            mission.value = true;
            vision.value = false;
            historique.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showH = () => {
            historique.value = true;
            vision.value = false;
            mission.value = false;
            organigramme.value = false;
            valeur.value = false;
        }

        const showO = () => {
            organigramme.value = true;
            vision.value = false;
            mission.value = false;
            historique.value = false;
            valeur.value = false;
        }

        function getActifApropos() {
            return ApiService.get('/apropos/statut/1')
            .then(response => {
                Aproposs.value = response.data.data;
                return response.data.data;
            })
            .catch(({ response }) => {
                error(response.data.message)
            });
        }
     
        onMounted(() => {
        getActifApropos();
        });


        return {
            Aproposs,
            vision,
            valeur,
            mission,
            historique,
            organigramme,
            showV,
            showVa,
            showM,
            showH,
            showO,
            getActifApropos
        };
    }
});
</script>